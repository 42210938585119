'use client'

import { PropsWithChildren } from 'react'
import { JssProvider, SheetsRegistry, createGenerateId } from 'react-jss'

export default function ReactJSSRegistry({ children }: PropsWithChildren) {
  const registry = new SheetsRegistry()
  const generateId = createGenerateId()

  return (
    <JssProvider generateId={generateId} registry={registry} isSSR={true}>
      {children}
    </JssProvider>
  )
}
